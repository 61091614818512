/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, colors, H2 } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { NavLink, useLocation } from 'react-router-dom'

import { SignupForm } from './Form/Form'
import { useForm } from '@bonitour/app-functions'
import { signupSchema } from './Form/Form.schema'

const buttonContainer = css`
  display: flex;
  a {
    align-self: center;
  }
  button {
    margin-left: auto;
  }
`

const returnLink = css`
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${colors.indigo2};
`

const initialState = {}
const preventFormDefault = event => event.preventDefault()

export const Signup = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const { search = '' } = useLocation()
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialState, signupSchema)

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)
  return (
    <form onSubmit={preventFormDefault}>
      <H2>
        Criar Conta
      </H2>
      <SignupForm signup={form} signupErrors={errors} onChange={onInputChange} onBlur={onInputBlur} />
      <div css={buttonContainer}>
        <NavLink to={{ pathname: '/auth/login', search }} css={returnLink}>
          Realizar Login
        </NavLink>
        <Button type='submit' onClick={onClick}>Cadastrar</Button>
      </div>
    </form>
  )
}
