import { CardBrandParser } from '@binamik/pay-checkout'
import { formatDate } from '@bonitour/common-functions'

export const CheckoutParser = {
  feeTitle (fees = []) {
    return fees.map(({ fee_type: name, total }) => `${total} ${name}${total > 1 ? 's' : ''}`).join(',')
  },
  item ({
    company_name: title = '',
    service_name: subTitle = '',
    slot = '',
    fee = []
  }) {
    const date = formatDate(slot)
    const hour = slot.slice(11, 16)
    const description = CheckoutParser.feeTitle(fee)
    return {
      title,
      subTitle,
      date: `${date} - ${hour}`,
      description
    }
  },
  summary ({ price = 0, discount = 0, total }) {
    const totalAsNumber = Number(total)
    return {
      price: price || totalAsNumber + discount,
      total: totalAsNumber
    }
  },
  cart ({ tickets_checkout: tickets = [], reservation_id: reservationId, max_installments: maxInstallments, ...args }) {
    const items = tickets.map(CheckoutParser.item)
    const summary = CheckoutParser.summary(args)
    return { items, reservationId, maxInstallments, ...summary }
  },
  cardBrand ({ gateway_card_brands: cardBrands = [] }) {
    return cardBrands.map(CardBrandParser.card)
  },
  reservationInfo ({ reservation_payment: reservation }) {
    const { reservation_id: reservationId, reservation_code: reservationCode } = reservation
    return {
      reservationId,
      reservationCode
    }
  }
}
