/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { CartContainer, CartDetails, CartItem, CartItemsContainer, CartSummary, CartTitle } from '@binamik/pay-checkout'

export const CheckoutCart = ({ cart = {} }) => {
  const { items = [], price, discount, total, cashback } = cart
  return (
    <CartContainer>
      <CartTitle>
        Resumo da compra
      </CartTitle>
      <CartDetails>
        <CartItemsContainer>
          {
            items.map(item => {
              return (
                <CartItem
                  key={item.title}
                  title={item.title}
                  subTitle={item.subTitle}
                  date={item.date}
                  description={item.description}
                />
              )
            })
          }
        </CartItemsContainer>
        <CartSummary
          price={price}
          discount={discount}
          total={total}
          cashback={cashback}
        />
      </CartDetails>
    </CartContainer>
  )
}
