/** @jsxRuntime classic */
/** @jsx jsx */
import { useCallback, useEffect, useMemo } from 'react'
import { jsx, css } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { H1, Button, useToast, colors } from '@bonitour/components'
import { CheckoutInfoForm, checkoutInfoSchema } from '@binamik/pay-checkout'

import { useZipCode } from 'Shared/hooks/useZipCode'
import { useAddressLocation } from 'Shared/hooks/useAddressLocation'
import { buildAutoFiller } from 'Shared/utils/autoFill'

export const autoFillCss = css`
  position: fixed;
  bottom: 10px;
  left: 60px;
  background-color: #e55;
  color: ${colors.white};
  font-weight: 700;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: #e33;
  }
`

export const CheckoutInfoStep = ({ info = {}, onSuccess = identity, ...props }) => {
  const { add: addToast } = useToast()
  const { form, errors, setForm, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(info, checkoutInfoSchema)

  const [requestedZipCode, requestZipCodeInfo] = useZipCode(addToast)
  const {
    countries,
    states,
    cities,
    updateAvailableStates,
    updateAvailableCities
  } = useAddressLocation(addToast)

  const onValidationError = () => addToast('Preencha o formulário corretamente')
  const onSuccessValidation = (form) => onSuccess({ ...form, countries, states, cities })
  const onClick = onSubmit(onSuccessValidation, onValidationError)

  const onZipCodeBlur = useCallback(
    (zip = form.zipCode) => {
      requestZipCodeInfo(zip)
      onInputBlur('zipCode')()
    },
    [form.zipCode, onInputBlur, requestZipCodeInfo]
  )

  const onCountryChange = useCallback(
    countryValue => {
      const country = countries
        .find(country => Object.values(country).includes(countryValue))
      if (country) {
        updateAvailableStates(country.value)
        onInputChange('country')(country.value)
      } else {
        updateAvailableStates(null)
        onInputChange('country')('')
        onInputChange('state')('')
        onInputChange('city')('')
      }
    },
    [countries, updateAvailableStates, onInputChange]
  )

  const onStateChange = useCallback(
    stateValue => {
      const state = states
        .find(state => Object.values(state).includes(stateValue))
      if (state) {
        updateAvailableCities(form.country, state.value)
        onInputChange('state')(state.value)
      } else {
        updateAvailableCities(null)
        onInputChange('state')('')
        onInputChange('city')('')
      }
    },
    [states, updateAvailableCities, form.country, onInputChange]
  )

  const onCityChange = useCallback(
    cityValue => {
      const city = cities
        .find(city => Object.values(city).includes(cityValue))
      if (city) {
        onInputChange('city')(city.value)
      } else {
        onInputChange('city')('')
      }
    },
    [cities, onInputChange]
  )

  const updateAddress = useCallback(() => {
    if (Object.keys(requestedZipCode).length) {
      setForm(previousForm => ({
        ...previousForm,
        address: requestedZipCode.address,
        district: requestedZipCode.district,
        state: requestedZipCode.state,
        city: requestedZipCode.city
      }))
      onCountryChange('BR')
    }
  }, [setForm, onCountryChange, requestedZipCode])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAddress, [requestedZipCode])

  const updateCountries = useCallback(() => {
    if (countries?.length) {
      onCountryChange(form.country || info.country)
    }
  }, [countries, onCountryChange, form.country, info.country])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCountries, [countries])

  const updateStates = useCallback(() => {
    if (states?.length) {
      onStateChange(form.state || info.state)
    }
  }, [states, onStateChange, form.state, info.state])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStates, [states])

  const updateCities = useCallback(() => {
    if (cities?.length) {
      onCityChange(form.city || info.city)
    }
  }, [cities, onCityChange, form.city, info.city])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateCities, [cities])

  const autoFill = useMemo(() => buildAutoFiller('buyerInfo'), [])

  return (
    <div {...props}>
      {autoFill
        ? (
          <button
            onClick={() => autoFill({ onInputChange, onZipCodeBlur })}
            css={autoFillCss}
            className={'autofill'}
            type="button"
          >
            AUTOFILL buyer
          </button>
        )
        : null}
      <H1>
        Responsável pela compra
      </H1>
      <CheckoutInfoForm
        form={form}
        errors={errors}
        countries={countries}
        states={states}
        cities={cities}
        onZipCodeBlur={onZipCodeBlur}
        onInputChange={onInputChange}
        onInputBlur={onInputBlur}
        onStateChange={onStateChange}
        onCountryChange={onCountryChange}
      />
      <Button onClick={onClick}>
        Continuar
      </Button>
    </div>
  )
}
