const languages = {
  pt: "pt_BR",
  pt_br: "pt_BR",
  "pt-br": "pt_BR",

  en: "en_US",
  en_us: "en_US",
  "en-us": "en_US",

  es: "es_ES",
  es_es: "es_ES",
  "es-es": "es_ES",
};

export const getLanguageCode = (
  locale: string,
): "pt_BR" | "en_US" | "es_ES" => {
  const lowerCasedLocale = (locale || "pt").toLowerCase();
  return languages[lowerCasedLocale] || languages.pt;
};
