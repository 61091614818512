import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const container = css`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`

export const reservationNumber = css`
  color: ${colors.primary};
`

export const bold = css`
  font-weight: bold;
`

export const qrcode = css`
  width: 100px;
  height: 100px;
`

export const shopInfo = css`
  max-width: 800px;

  @media screen and (max-width: 1150px) {
    margin-bottom: 25px;
  }
`

export const card = css`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const cardTitle = css`
  background-color: ${colors.gray5};
  border-radius: 9px 9px 0px 0px;
  width: 100%;  
`

export const cardContent = css`
  background-color: white;
  border: 1px solid #F0F0F0;
  border-radius: 0 0 10px 10px;
  padding: 21px;
`

export const cardTitleText = css`
  margin: 10px 10px 10px 15px;
  color: ${colors.white};
`

export const cardImageContainer = css`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`

export const cardQrText = css`
  font-size: 10px;
  margin-bottom: 0;
  text-align: center;
`

export const cashBackSteps = css`
  margin-bottom: 20px;
`

export const shopInfoTitle = css`
  @media screen and (max-width: 1150px) {
    text-align: center;
  }
`
