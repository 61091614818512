import React, { createContext, useContext, useCallback, useMemo } from 'react'
import { useQuery } from 'Shared/hooks/useQuery'
import { AuthService } from 'Core/Service/Auth'
import { useUser } from './User'
import { useHistory } from 'react-router-dom'

export const AuthenticationContext = createContext({})

export const useAuthentication = () => useContext(AuthenticationContext)

export const AuthenticationProvider = (props) => {
  const history = useHistory()
  const { path } = useQuery()
  const { updateUser, updateAuthKey } = useUser()

  const redirectClient = useCallback(() => {
    if (path) {
      history.push(path)
    } else {
      history.push('/checkout')
    }
  }, [history, path])

  const saveCredentials = useCallback(({ user, authorization }) => {
    updateUser(user)
    updateAuthKey(authorization)
  }, [updateUser, updateAuthKey])

  const login = useCallback(
    (payload) => AuthService.login(payload)
      .then(saveCredentials)
      .then(redirectClient),
    [saveCredentials, redirectClient]
  )
  const oauthLogin = useCallback(
    (token) => AuthService.oauthCallback({ temp_token: token })
      .then(login),
    [login]
  )
  const signup = useCallback(
    (payload) => AuthService.signup(payload)
      .then(saveCredentials)
      .then(redirectClient),
    [saveCredentials, redirectClient]
  )

  const providerData = useMemo(() => ({ login, signup, oauthLogin }), [login, oauthLogin, signup])

  return (
    <AuthenticationContext.Provider value={providerData} {...props} />
  )
}
