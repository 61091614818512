/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Content, Header, Footer, Logo } from '@bonitour/components'

import BinamikLogo from 'Shared/svgs/logoAlly.svg'
import { Feature } from 'Shared/contexts/Feature'
import {
  logo,
  container,
  formSubmitGroup,
  overrideBaseLayout,
  headerLogo
} from './AuthLayout.style'

export const AuthLayout = props => {
  return (
    <Feature>
      <Header css={headerLogo}>
        <Logo css={logo} src={BinamikLogo} />
      </Header>
      <Content css={overrideBaseLayout}>
        <div css={container}>
          <div css={formSubmitGroup} {...props} />
        </div>
      </Content>
      <Footer />
    </Feature>
  )
}
