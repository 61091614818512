/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Email, Password, InputFormGroup, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { NavLink, useLocation } from 'react-router-dom'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'

export const LoginForm = ({
  loginForm = {},
  loginFormErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity
}) => {
  const { email = '', password = '' } = loginForm
  const { search = '' } = useLocation()

  return (
    <>
      <InputFormGroup errorMessage={loginFormErrors.email} label='E-mail'>
        <Email
          name='email'
          value={email}
          onChange={emitChangeEvent('email')}
          onBlur={emitBlurEvent('email')}
          required
          tabIndex={1}
        />
      </InputFormGroup>

      <InputFormGroup
        errorMessage={loginFormErrors.password}
        label={(
          <div css={flexCenteredSpaceBetweenRow}>
            <span>Senha</span>
            <NavLink to={{ pathname: '/auth/forgot-password', search }} css={returnLink}>
              Esqueceu?
            </NavLink>
          </div>
        )}
      >
        <Password
          name='password'
          value={password}
          onChange={emitChangeEvent('password')}
          onBlur={emitBlurEvent('password')}
          required
          tabIndex={1}
        />
      </InputFormGroup>
    </>
  )
}
