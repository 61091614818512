/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { formatMoney } from "@bonitour/common-functions";
import { colors } from "@bonitour/components";
import { getLanguageCode } from "../../../utils/getLanguageCode";

export const summaryContainer = css`
  padding: 15px 20px;
`;

export const summaryItem = css`
  display: flex;
  margin-bottom: 10px;
  * {
    flex-grow: 1;
  }
`;

export const lineThrough = css`
  text-decoration: line-through;
`;

export const itemText = css`
  color: ${colors.gray3};
  font-weight: 500;
  font-size: 14px;
  text-align: left;
`;

export const itemMoney = css`
  ${itemText}
  font-weight: bolder;
  text-align: right;
`;

export const marginTop20 = css`
  margin-top: 20px;
`;

export const totalLine = css`
  font-weight: bold;
  font-size: 16px;
`;

export const colorSecondary = css`
  color: ${colors.secondary};
`;

type CartSummaryProps = {
  lang?: string;
  price?: number;
  discount?: number;
  total?: number;
  cashback?: number;
};

const cartSummaryI18nTranslation = {
  pt_BR: {
    subTotalInfo: "Subtotal",
    totalInfo: "Total",
    discountInfo: "Desconto",
    cashbackInfo: "Cashback",
  },
  en_US: {
    subTotalInfo: "Subtotal",
    totalInfo: "Total",
    discountInfo: "Discount",
    cashbackInfo: "Cashback",
  },
  es_ES: {
    subTotalInfo: "Total parcial",
    totalInfo: "Total",
    discountInfo: "Descuento",
    cashbackInfo: "", // TODO
  },
};

export const CustomCartSummary = ({
  lang,
  price,
  discount,
  total,
  cashback,
}: CartSummaryProps): JSX.Element => {
  return (
    <div css={summaryContainer}>
      <div css={summaryItem}>
        <div css={itemText}>
          {cartSummaryI18nTranslation[lang].subTotalInfo}
        </div>
        <div css={itemMoney}>{formatMoney(price)}</div>
      </div>
      <div css={summaryItem}>
        <div css={itemText}>
          {cartSummaryI18nTranslation[lang].discountInfo}
        </div>
        <div css={[itemMoney, lineThrough]}>{formatMoney(discount)}</div>
      </div>
      <div css={[summaryItem, marginTop20]}>
        <div css={[itemText, totalLine]}>
          {cartSummaryI18nTranslation[lang].totalInfo}
        </div>
        <div css={[itemMoney, totalLine]}>{formatMoney(total)}</div>
      </div>
      {Boolean(cashback) && (
        <div css={summaryItem}>
          <div css={[itemText, colorSecondary]}>
            {cartSummaryI18nTranslation[lang].cashbackInfo}
          </div>
          <div css={[itemMoney, colorSecondary]}>{formatMoney(cashback)}</div>
        </div>
      )}
    </div>
  );
};

export const CartSummary = ({
  lang = "pt",
  ...props
}: CartSummaryProps): JSX.Element => (
  <CustomCartSummary lang={getLanguageCode(lang)} {...props} />
);
