import { IpApi } from 'Core/Api/Ip'

export const IpService = {
  get (requestsMade = 0) {
    return IpApi.get()
      .catch(() => {
        if (requestsMade < 3) {
          return IpService.get(requestsMade + 1)
        }
        throw new Error('Request to API failed')
      })
  }
}
