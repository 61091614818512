export const addIframe = (url) => {
  const div = document.createElement('div')
  const iframe = document.createElement('iframe')
  iframe.style.cssText = 'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;'
  iframe.src = url
  div.appendChild(iframe)
  document.body.appendChild(iframe)
}

export const addScript = (url, isAsync = false) => {
  const script = document.createElement('script')

  script.src = url
  script.async = isAsync

  document.body.appendChild(script)
}
