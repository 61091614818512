import { LocalitiesApi } from 'Core/Api/Address'
import { useCallback, useEffect, useState } from 'react'

const { listCities, listCountries, listStates } = LocalitiesApi

export const useAddressLocation = (addToast, defaultCountry = '', defaultState = '') => {
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const updateAvailableStates = useCallback(
    selectedCountry => {
      listStates(selectedCountry)
        .then(states => {
          setStates(states)
          setCities([])
        })
        .catch(() => {
          setCities([])
          setStates([])
          addToast('Ocorreu um erro na busca pelo país')
        })
    },
    [addToast]
  )

  const updateAvailableCities = useCallback(
    (selectedCountry, selectedState) => {
      listCities(selectedCountry, selectedState)
        .then(setCities)
        .catch(() => {
          setCities([])
          addToast('Ocorreu um erro na busca pelo estado')
        })
    },
    [addToast]
  )

  useEffect(() => {
    listCountries().then(setCountries)
  }, [])

  useEffect(() => {
    if (defaultCountry && defaultState) {
      updateAvailableStates(defaultCountry)
      updateAvailableCities(defaultCountry, defaultState)
    }
  }, [defaultCountry, defaultState, updateAvailableStates, updateAvailableCities])

  return {
    countries,
    states,
    cities,
    updateAvailableStates,
    updateAvailableCities
  }
}
