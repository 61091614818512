/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import {
  InputFormGroup,
  Input,
  Column,
  DatePicker,
  Row,
  DocumentInput,
  MaskedInput,
  Select,
  PhoneSelector,
  Card,
} from "@bonitour/components";
import { number, string, date } from "yup";

import {
  maskPhone,
  identity,
  validateCpf,
  validatePhone,
  validatePhoneWithDDI,
} from "@bonitour/common-functions";
import { FormCallback, SelectOption, StateChangeFuncion } from "@/types/Form";
import {
  infoFormI18nTranslation,
  infoSchemaI18nTranslation,
} from "./i18n/infoFormDictionaryI18n";
import { getLanguageCode } from "../../../utils/getLanguageCode";
import { Fragment } from "react";
import { RequiredStringSchema } from "yup/lib/string";
import { RequiredDateSchema } from "yup/lib/date";
import { RequiredNumberSchema } from "yup/lib/number";

const fullWidth = css`
  min-width: auto;
  width: 100%;
`;

const phoneInputStyle = css`
  input {
    border-radius: 100vw !important;
    max-width: 100% !important;
  }
`;

const marginBottom20 = css`
  margin-bottom: 20px;
`;

type InfoFormFields = {
  name?: string;
  birthDate?: string;
  identificationDocument?: string;
  identificationDocumentType?: string;
  number?: string;
  zipCode?: string;
  address?: string;
  addressNumber?: number;
  addressDetail?: string;
  district?: string;
  country?: string;
  state?: string;
  city?: string;
};

interface InfoFormInterface {
  disabled?: boolean;
  disabledFields?: Array<string>;
  form: InfoFormFields;
  errors: InfoFormFields;
  countries: Array<SelectOption>;
  states: Array<SelectOption>;
  cities: Array<SelectOption>;
  onInputBlur: FormCallback;
  onInputChange: FormCallback;
  onZipCodeBlur: StateChangeFuncion;
  onStateChange: StateChangeFuncion;
  onCountryChange: StateChangeFuncion;
  lang?: string;
  withDDI?: boolean;
  asCard?: boolean;
}

const availableDocuments = [
  {
    value: "Cpf",
    label: "CPF",
  },
  {
    value: "Passport",
    label: "Passaporte",
  },
  {
    value: "Other",
    label: "Outro",
  },
];

interface WrapperProps {
  asCard: boolean;
  children: JSX.Element;
}

const FormWrapper = ({ asCard, children }: WrapperProps): JSX.Element => {
  return asCard ? (
    <Card css={marginBottom20}>{children}</Card>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export const CheckoutInfoForm = ({
  form = {},
  errors = {},
  countries = [],
  states = [],
  cities = [],
  disabled = false,
  disabledFields = [],
  onZipCodeBlur = identity,
  onStateChange = identity,
  onCountryChange = identity,
  onInputBlur = identity,
  onInputChange = identity,
  lang = "pt",
  withDDI = false,
  asCard = false,
}: InfoFormInterface): JSX.Element => {
  const {
    name = "",
    birthDate = "",
    identificationDocument = "",
    identificationDocumentType = "",
    number = "",
    zipCode = "",
    address = "",
    addressDetail = "",
    addressNumber = "",
    district = "",
    country = "",
    state = "",
    city = "",
  } = form;

  const langCode = getLanguageCode(lang);

  return (
    <FormWrapper asCard={asCard}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].fullNameLabel}
            errorMessage={errors.name}
            id="name"
          >
            <Input
              css={fullWidth}
              value={name}
              error={errors.name}
              onChange={onInputChange("name")}
              onBlur={onInputBlur("name")}
              disabled={disabled || disabledFields.includes("name")}
              autoComplete="name"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].documentLabel}
            errorMessage={errors.identificationDocument}
            id="identificationDocument"
          >
            <DocumentInput
              documentType={identificationDocumentType}
              documentValue={identificationDocument}
              onDocumentChange={onInputChange("identificationDocumentType")}
              onDocumentBlur={onInputBlur("identificationDocumentType")}
              onDocumentValueChange={onInputChange("identificationDocument")}
              onDocumentValueBlur={onInputBlur("identificationDocument")}
              availableDocuments={availableDocuments}
              disabled={
                disabled || disabledFields.includes("identificationDocument")
              }
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].birthDateLabel}
            errorMessage={errors.birthDate}
            id="birthDate"
          >
            <DatePicker
              value={birthDate}
              css={fullWidth}
              onChange={onInputChange("birthDate")}
              onBlur={onInputBlur("birthDate")}
              disabled={disabled || disabledFields.includes("birthDate")}
              allowsEmpty
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].phoneNumberLabel}
            id="phone"
            errorMessage={errors.number}
          >
            {withDDI ? (
              <PhoneSelector
                customId="phone"
                value={number}
                error={Boolean(errors.number)}
                onChange={onInputChange("number")}
                onBlur={onInputBlur("number")}
                customCss={[fullWidth, phoneInputStyle]}
                disabled={disabled}
                countryCodeEditable={false}
              />
            ) : (
              <MaskedInput
                value={number}
                error={errors.number}
                css={fullWidth}
                onChange={onInputChange("number")}
                onBlur={onInputBlur("number")}
                formatterFunction={maskPhone}
                placeholder="(00) 0000-0000"
                disabled={disabled}
                autoComplete="tel"
              ></MaskedInput>
            )}
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].zipCodeLabel}
            id="zipCode"
            errorMessage={errors.zipCode}
          >
            <Input
              css={fullWidth}
              value={zipCode}
              onChange={onInputChange("zipCode")}
              onBlur={onZipCodeBlur}
              disabled={disabled}
              autoComplete="postal-code"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].addressLabel}
            id="address"
          >
            <Input
              value={address?.substring(0, 24)}
              error={errors.address}
              onChange={onInputChange("address")}
              onBlur={onInputBlur("address")}
              maxLength={24}
              disabled={disabled}
              autoComplete="street-address"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].addressNumberLabel}
            id="district"
          >
            <Input
              type="number"
              value={addressNumber}
              error={errors.addressNumber}
              onChange={onInputChange("addressNumber")}
              onBlur={onInputBlur("addressNumber")}
              max={99999}
              disabled={disabled}
              autoComplete="address-line1"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={2}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].complementLabel}
            id="addressDetail"
          >
            <Input
              css={fullWidth}
              value={addressDetail}
              error={errors.addressDetail}
              onChange={onInputChange("addressDetail")}
              onBlur={onInputBlur("addressDetail")}
              maxLength={14}
              disabled={disabled}
              autoComplete="address-line1"
            />
          </InputFormGroup>
        </Column>

        <Column phone={12} desktop={2}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].districtLabel}
            id="district"
          >
            <Input
              value={district?.substring(0, 15)}
              error={errors.district}
              onChange={onInputChange("district")}
              onBlur={onInputBlur("district")}
              maxLength={15}
              disabled={disabled}
              autoComplete="address-level2"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            label={infoFormI18nTranslation[langCode].countryLabel}
            id="country"
          >
            <Select
              options={countries}
              value={country}
              error={errors.country}
              onChange={onCountryChange}
              onBlur={onInputBlur("country")}
              disabled={disabled}
              autoComplete="country"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            errorMessage={errors.state}
            label={infoFormI18nTranslation[langCode].stateLabel}
            id="state"
          >
            <Select
              options={states}
              value={state}
              onChange={onStateChange}
              onBlur={onInputBlur("state")}
              disabled={disabled}
              autoComplete="address-level1"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={4}>
          <InputFormGroup
            errorMessage={errors.city}
            label={infoFormI18nTranslation[langCode].cityLabel}
            id="city"
          >
            <Select
              options={cities}
              value={city}
              onChange={onInputChange("city")}
              onBlur={onInputBlur("city")}
              disabled={disabled}
              autoComplete="address-level2"
            />
          </InputFormGroup>
        </Column>
      </Row>
    </FormWrapper>
  );
};

interface SchemaProps {
  name: RequiredStringSchema<string>;
  birthDate: RequiredDateSchema<Date>;
  identificationDocumentType: RequiredStringSchema<string>;
  identificationDocument: RequiredStringSchema<string>;
  number: RequiredStringSchema<string>;
  zipCode: RequiredStringSchema<string>;
  address: RequiredStringSchema<string>;
  addressNumber: RequiredNumberSchema<number>;
  addressDetail: RequiredStringSchema<string>;
  district: RequiredStringSchema<string>;
  country: RequiredStringSchema<string>;
  state: RequiredStringSchema<string>;
  city: RequiredStringSchema<string>;
}

export const customCheckoutInfoSchema = ({
  lang,
  withDDI = false,
}: {
  lang?: string;
  withDDI?: boolean;
}): SchemaProps => {
  const langCode = getLanguageCode(lang);
  const minDate = new Date(1900, 0, 1);
  const lastYear = new Date(new Date().getFullYear() - 1, 1, 1);

  return {
    name: string()
      .trim()
      .required(infoSchemaI18nTranslation[langCode].fullNameAlert[0])
      .matches(/\s/, infoSchemaI18nTranslation[langCode].fullNameAlert[1]),
    birthDate: date()
      .typeError(infoSchemaI18nTranslation[langCode].birthDateAlert)
      .required(infoSchemaI18nTranslation[langCode].birthDateAlert)
      .min(minDate, infoSchemaI18nTranslation[langCode].birthDateInvalidAlert)
      .max(lastYear, infoSchemaI18nTranslation[langCode].birthDateInvalidAlert),
    identificationDocumentType: string(),
    identificationDocument: string().when("identificationDocumentType", {
      is: "Cpf",
      then: string()
        .required(infoSchemaI18nTranslation[langCode].documentAlert[0])
        .test(
          "test-cpf",
          infoSchemaI18nTranslation[langCode].documentAlert[1],
          validateCpf,
        ),
      otherwise: string().required(
        infoSchemaI18nTranslation[langCode].documentAlert[2],
      ),
    }),
    number: string()
      .required(infoSchemaI18nTranslation[langCode].phoneNumberAlert[0])
      .test(
        "validate-phone",
        infoSchemaI18nTranslation[langCode].phoneNumberAlert[1],
        withDDI ? validatePhoneWithDDI : validatePhone,
      ),
    addressNumber: number()
      .typeError(infoSchemaI18nTranslation[langCode].addressNumberAlert[0])
      .required(infoSchemaI18nTranslation[langCode].addressNumberAlert[1]),
    zipCode: string().required(
      infoSchemaI18nTranslation[langCode].zipCodeAlert,
    ),
    address: string().required(
      infoSchemaI18nTranslation[langCode].addressAlert,
    ),
    addressDetail: string(),
    district: string().required(
      infoSchemaI18nTranslation[langCode].districtAlert,
    ),
    country: string()
      .required(infoSchemaI18nTranslation[langCode].countryAlert)
      .uuid(infoSchemaI18nTranslation[langCode].uuidAlert),
    state: string()
      .required(infoSchemaI18nTranslation[langCode].stateAlert)
      .uuid(infoSchemaI18nTranslation[langCode].uuidAlert),
    city: string()
      .test("city", infoSchemaI18nTranslation[langCode].cityAlert, function () {
        const { state, city } = this.parent;
        const isCityEmpty = city?.length === 0;
        const isStateEmpty = state?.length === 0;

        if (isCityEmpty && !isStateEmpty) {
          return false;
        } else {
          return true;
        }
      })
      .uuid(infoSchemaI18nTranslation[langCode].uuidAlert),
  };
};

export const checkoutInfoSchema = customCheckoutInfoSchema({ lang: "pt" });
