// @ts-check
/** @jsxRuntime classic */
/** @jsx jsx */
import { H1, GhostButton, useToast, AsyncButton } from '@bonitour/components'
import { jsx, css } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { useForm } from '@bonitour/app-functions'

import { CheckoutPaymentForm, checkoutPaymentSchema } from '@binamik/pay-checkout'
import { useCreditCardFlags } from 'Domains/Checkout/hooks/useCreditCardFlags'
import { useEffect, useMemo } from 'react'
import { buildAutoFiller } from 'Shared/utils/autoFill'
import { autoFillCss } from './Info'

const marginRight10 = css`
  margin-right: 10px;
`

export const CheckoutPaymentStep = ({
  payment = {},
  maxInstallments,
  onSuccess = identity,
  onBackButton = identity,
  isCartLoading = false,
  ...props
}) => {
  const { add: addToast } = useToast()
  const { form, errors, onSubmit, utils: { onInputBlur, onInputChange } } = useForm(payment, checkoutPaymentSchema)
  const { selectedBrand, cardOptions, installmentsOptions } = useCreditCardFlags(form, maxInstallments)

  useEffect(() => {
    onInputChange('cardFlag')(selectedBrand && selectedBrand.brand)
    // eslint-disable-next-line
  }, [selectedBrand])

  const onValidationError = () => addToast('Preencha o formulário corretamente')

  const onBackClick = () => onBackButton(form)
  const onClick = onSubmit(onSuccess, onValidationError)

  const autoFill = useMemo(() => buildAutoFiller('cardInfo'), [])

  return (
    <div {...props}>
      {autoFill
        ? (
          <button
            onClick={() => autoFill({ onInputChange })}
            css={autoFillCss}
            className={'autofill'}
            style={{ transform: 'translateX(58px)' }}
          >
            AUTOFILL card
          </button>
        )
        : null
      }
      <H1>
        Escolha a forma de Pagamento
      </H1>
      <CheckoutPaymentForm
        form={form}
        isFlagSelectDisabled
        errors={errors}
        onInputBlur={onInputBlur}
        onInputChange={onInputChange}
        installmentsOptions={installmentsOptions}
        cardOptions={cardOptions}
      />
      <GhostButton css={marginRight10} onClick={onBackClick}>
        Voltar
      </GhostButton>
      <AsyncButton onClick={onClick} disabled={isCartLoading}>
        Concluir a compra
      </AsyncButton>
    </div>
  )
}
