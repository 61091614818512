import { OrbService } from 'Core/Service/Orb'

const loginResource = '/login'
const oauthLoginResource = '/login/external_providers/oauth_callback'
const signupResource = '/signup'
const forgotPasswordResource = '/passwords/forgot_password'

export const AuthApi = {
  login (payload) {
    const { email, password, authorization } = payload
    if ('authorization' in payload) {
      return OrbService.post(loginResource, {}, {
        headers: {
          Authorization: authorization
        }
      })
    }
    return OrbService.post(loginResource, {
      user: { email, password }
    })
  },
  oauthCallback (payload) {
    return OrbService.get(oauthLoginResource, { params: payload })
  },
  signup (payload) {
    const { name, email, password } = payload
    const legalAgreementTypes = ['tourist_terms_of_use', 'privacy_policy', 'cookies_policy']
    return OrbService.post(signupResource, {
      user: { name, email, password, legal_agreement_types: legalAgreementTypes }
    })
  },
  forgotPassword ({ email }) {
    return OrbService.post(forgotPasswordResource, { email })
  }
}
