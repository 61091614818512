/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, colors, flexCenteredSpaceBetweenRow, H2 } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { NavLink, useLocation } from 'react-router-dom'
import { ResetPasswordForm } from './Form/Form'
import { resetPasswordFormSchema } from './Form/Form.schema'

const returnLink = css`
  margin: 5px 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${colors.primary};
`
const preventFormDefault = event => event.preventDefault()

export const ResetPasswordPage = ({
  resetPasswordForm = {},
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(resetPasswordForm, resetPasswordFormSchema)
  const { search = '' } = useLocation()

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <form onSubmit={preventFormDefault}>
      <H2>Alteração de senha</H2>
      <ResetPasswordForm
        resetPasswordForm={form}
        resetPasswordFormErrors={errors}
        onChange={onInputChange}
        onBlur={onInputBlur}
        onSubmit={onClick}
      />
      <div css={flexCenteredSpaceBetweenRow}>
        <NavLink to={{ pathname: '/auth/login', search }} css={returnLink}>Realizar Login</NavLink>
        <Button type='submit' onClick={onClick}>Atualizar</Button>
      </div>
    </form>
  )
}
