const valuesToFill = {
  buyerInfo: {
    name: 'John Doe',
    identificationDocument: '01234567890',
    birthDate: new Date('2000-01-01'),
    number: '4831810333',
    zipCode: '88032-005',
    addressDetail: 'Lagoa B, 318',
    addressNumber: '5500',
    extraFunctions: {
      onZipCodeBlur: '88032-005'
    }
  },
  cardInfo: {
    cardNumber: '5555555555554444',
    holder: 'John Doe',
    installments: 1,
    cardValidityDate: '01/2035',
    cardCode: '111'
  }
}

const APP_ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV
const DEV_ENVIRONMENT = [
  'local',
  'staging',
  'development',
  'homologation'
].includes(APP_ENVIRONMENT)

export const buildAutoFiller = formName =>
  !DEV_ENVIRONMENT || !(formName in valuesToFill)
    ? null
    : ({ onInputChange, ...extraFunctions }) => {
      Object.keys(valuesToFill[formName]).forEach(key => {
        const value = valuesToFill[formName][key]
        if (key === 'extraFunctions') {
          Object.keys(value).forEach(key => {
            if (typeof extraFunctions[key] === 'function') {
              extraFunctions[key](value[key])
            }
          })
        } else {
          onInputChange(key)(value)
        }
      })
    }
