import { string, ref, bool } from 'yup'

export const signupSchema = {
  name: string().required('O nome completo é obrigatório'),
  email: string()
    .email()
    .required('O email é obrigatório'),
  emailConfirmation: string()
    .email()
    .test('same-email', 'Os emails devem ser iguais', function (emailConfirmation) {
      return emailConfirmation === this.resolve(ref('email'))
    })
    .required('A confirmação do email é obrigatória'),
  password: string().required('Preencha o campo de senha'),
  serviceTerms: bool()
    .oneOf([true], 'A confirmação é obrigatória')
    .required('A confirmação é obrigatória')
}
