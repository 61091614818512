/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'

import { LoginPage } from './Page'
import { useAuthentication } from 'Shared/contexts/Authentication'

export const Login = () => {
  const { add: addToast } = useToast()
  const { login } = useAuthentication()

  const onSuccess = async data => {
    try {
      await login(data)
    } catch (error) {
      addToast('Senha ou e-mail inválidos')
    }
  }

  const onValidationError = () => addToast('Digite seu e-mail e senha')

  return <LoginPage onSuccess={onSuccess} onValidationError={onValidationError} />
}
