/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "@bonitour/components";
import { JustWithChildren } from "@/types/Utils";

const cartContainer = css`
  border-radius: 10px;
  border: 1px solid ${colors.gray11};
  overflow: hidden;
  min-height: 100%;
`;

export const CartContainer = ({ children }: JustWithChildren): JSX.Element => (
  <div css={cartContainer}>{children}</div>
);
