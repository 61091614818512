/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading } from '@bonitour/components'

const checkoutHeadings = [
  {
    title: 'Processando seu pagamento'
  },
  {
    title: 'Concretizando seu sonho de viajar',
    timeout: 3000
  },
  {
    title: 'Já vai pensando em como você preparará sua mala',
    timeout: 5000
  },
  {
    title: 'Fazendo os últimos passos para seu sonho se tornar realidade',
    timeout: 9000
  }
]

const cartHeadings = [
  {
    title: 'Obtendo informações do carrinho'
  }
]

export const CheckoutLoading = ({ isLoading = false }) => {
  return (
    <Loading isLoading={isLoading} headings={checkoutHeadings} />
  )
}

export const CartLoading = ({ isLoading = false }) => {
  return (
    <Loading isLoading={isLoading} headings={cartHeadings} />
  )
}
