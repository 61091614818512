import { colors } from "@bonitour/components";
import { css } from "@emotion/core";

export const cardNameContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${colors.gray3};

  .card_name__row {
    display: flex;
    gap: 2px;
    align-items: center;
    flex-direction: row;
  }

  .icon__ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid ${colors.gray3};
    background-color: transparent;
    transform: scale(0.8);
    transform-origin: left;
    .tooltip-on-hover {
      transform: scale(1.25);
      transform-origin: bottom;
      > div {
        transform: rotate(-90deg) scale(1.25) translateY(2px);
      }
    }
    .icon {
      transform: rotate(180deg);
    }
  }
`;

export const fullWidth = css`
  min-width: auto;
  width: 100%;
`;

export const marginBottom20 = css`
  margin-bottom: 20px;
`;

export const pasteIcon = css`
  font-size: 20px;
  color: ${colors.gray2};
  cursor: pointer;
`;
