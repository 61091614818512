import { initialize } from '@bonitour/orb-core'
import { UserStore } from 'Core/Store/User'

const { REACT_APP_API_HOST } = process.env

const interceptorFunction = (interceptor) => {
  interceptor.request.use(
    config => {
      if (UserStore.authKey && !config.headers.Authorization) {
        config.headers.Authorization = UserStore.authKey
      }

      return config
    },
    error => Promise.reject(error)
  )
}

export const OrbCore = initialize(
  REACT_APP_API_HOST,
  interceptorFunction
)
