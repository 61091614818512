/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CheckoutPayer,
  CheckoutPayment,
  CheckoutPayments,
  SafeCheckoutPayment,
  SafeCheckoutPaymentData,
} from "@/types/Checkout";
import { toCents } from "@bonitour/common-functions";

type PaymentPayerInfo = {
  name: string;
  identificationDocument: string;
  email: string;
  address: string;
  addressDetail: string;
  birthDate: Date;
  city: string;
  country: string;
  addressNumber: number;
  district: string;
  number: string;
  state: string;
  zipCode: string;
};

type PaymentInfo = {
  type: string;
  installments: number;
  value: number;
  holder: string;
  cardNumber: string;
  cardFlag: string;
  cardValidityDate: string;
  cardCode: string;
};

type PaymentInput = {
  payment: PaymentInfo;
  info: PaymentPayerInfo;
  uuid: string;
  ipAddress: string;
};

type SafePaymentInfo = {
  type: string;
  installments: number;
  value: number;
  paymentToken: string;
  cardFlag: string;
};

type SafePaymentInput = {
  info: PaymentPayerInfo;
  uuid: string;
  ipAddress: string;
  payment: SafePaymentInfo;
};

export const CheckoutModel = {
  payerInfo(info: PaymentPayerInfo): CheckoutPayer {
    const {
      name,
      identificationDocument,
      email = "",
      address = "",
      addressDetail = "",
      birthDate = "",
      city = "",
      country = "",
      district = "",
      addressNumber = 0,
      number: phoneNumber = "",
      state = "",
      zipCode = "",
    } = info;
    const idWithoutMask = identificationDocument.replace(/\D/gi, "");
    const zipCodeWithoutMask = zipCode.replace(/\D/gi, "");
    const phoneNumberWithoutMask = phoneNumber.replace(/\D/gi, "");
    const [firstName, ...rest] = name.split(" ");

    return {
      first_name: firstName,
      last_name: rest.join(" "),
      email,
      phone: phoneNumberWithoutMask,
      birthdate: new Date(birthDate).toISOString().slice(0, 10),
      billing_address: {
        street: address.substring(0, 24),
        number: Number(addressNumber),
        zip_code: zipCodeWithoutMask,
        city: city.substring(0, 15),
        state,
        country,
        district: district.substring(0, 15),
        complement: addressDetail || undefined,
      },
      identification: {
        type: "CPF",
        number: idWithoutMask,
      },
    };
  },
  _safePaymentInfo(payment: SafePaymentInfo): SafeCheckoutPaymentData {
    const {
      type = "credit_card",
      installments,
      value,
      paymentToken,
      cardFlag,
    } = payment;

    const valueInCents = toCents(value);

    return {
      description: "BinamikPay",
      payment_token: paymentToken,
      payment_type: type,
      installments: installments,
      transaction_amount: valueInCents,
      credit_card_brand: cardFlag,
    };
  },

  safePayment(paymentInput: SafePaymentInput): SafeCheckoutPayment {
    const { payment, uuid = "", ipAddress = "", info } = paymentInput || {};
    return {
      gateway: "Braspag",
      payer: CheckoutModel.payerInfo(info),
      payments: CheckoutModel._safePaymentInfo(payment),
      antifraud: {
        finger_print_id: uuid,
        ip_address: ipAddress,
      },
    };
  },

  /**
   * @deprecated Use safePaymentInfo instead
   **/
  paymentInfo(_payment: PaymentInfo): CheckoutPayments {
    throw new Error(
      "Unsafe Method PaymentInfo is deprecated. Use safePaymentInfo instead.",
    );
  },
  /**
   * @deprecated Use safePayment instead
   **/
  payment(_paymentInput: PaymentInput): CheckoutPayment {
    throw new Error(
      "Unsafe Method Payment is deprecated. Use safePayment instead.",
    );
  },
};
