/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "@bonitour/components";
import { JustWithChildren } from "@/types/Utils";

const cartTitle = css`
  background: ${colors.gray5};
  padding: 6px 20px;
  font-size: 22px;
  font-weight: bold;
  color: ${colors.white};
`;
export const CartTitle = ({ children }: JustWithChildren): JSX.Element => (
  <div css={cartTitle}>{children}</div>
);
