/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading, useToast } from '@bonitour/components'
import { ForgotPasswordPage } from './Page'
import { AuthService } from 'Core/Service/Auth'
import { useState } from 'react'

const headings = [{
  title: 'Enviando solicitação'
}]

export const ForgotPassword = () => {
  const { add: addToast } = useToast()
  const [isLoading, setLoadingStatus] = useState(false)

  const onSuccess = async data => {
    setLoadingStatus(true)
    try {
      await AuthService.forgotPassword(data)
      addToast('Solicitação enviada com sucesso para o seu e-mail', 'success')
    } catch (error) {
      addToast('Ocorreu um erro na solicitação de alteração de senha')
    }
    setLoadingStatus(false)
  }

  const onValidationError = () => addToast('Digite um e-mail cadastrado no sistema')

  return (
    <>
      <ForgotPasswordPage onSuccess={onSuccess} onValidationError={onValidationError} />
      <Loading isLoading={isLoading} headings={headings} />
    </>
  )
}
