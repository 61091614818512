import React, { Fragment } from 'react'
import { ToastProvider } from '@bonitour/components'
import { css, Global } from '@emotion/core'
import 'reset-css'
import '@bonitour/font/style.css'
import { UserProvider } from 'Shared/contexts/User'
import { AuthenticationProvider } from 'Shared/contexts/Authentication'
import { BrowserRouter } from 'react-router-dom'

// avoding bug https://github.com/yannickcr/eslint-plugin-react/issues/2156
global.React = { Fragment }

const bodyStyle = css`
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");
  body {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }
`

const toastPosition = css`
  position: fixed;
  right: 0;
  bottom: 0;
`

export const Base = ({ children }) => (
  <BrowserRouter>
    <ToastProvider cssStyle={toastPosition}>
      <UserProvider>
        <AuthenticationProvider>
          <Global styles={bodyStyle} />
          {children}
        </AuthenticationProvider>
      </UserProvider>
    </ToastProvider>
  </BrowserRouter>
)
