import { css } from '@emotion/core'
import { colors, flexColumnCentered, flexColumn } from '@bonitour/components'

export const overrideBaseLayout = css`
  max-width: none;
  padding: 1.5rem 0;
  background-color: ${colors.gray15};
`

export const headerLogo = css`
  display: flex;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 5px #000000cc;
`

export const container = css`
  width: 100%;
  height: 100%;
  ${flexColumnCentered}
  align-self: center;
  align-items: inherit;
  max-width: calc(100% - 0.5rem);
  width: 440px;
`

export const logo = css`
  margin: auto;
  width: 114px;
`

export const primaryGhost = css`
  color: ${colors.primary};
  border-color: ${colors.primary};
`

export const formInputGroup = css`
  ${flexColumn}
  margin: 10px 0;
`

export const bonitourLink = css`
  color: ${colors.primary};
  text-decoration: underline;
  cursor: pointer;
  &:active,
  &:visited {
    color: ${colors.primary};
  }
`

export const formSubmitGroup = css`
  margin: 0 20px;
`

export const buttonContainer = css`
  display: flex;
  a {
    align-self: center;
  }
  button {
    margin-left: auto;
  }
`

export const returnLink = css`
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${colors.indigo2};
`

export const loadingCss = css`
  margin: 0 auto;
`
