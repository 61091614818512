import { useCallback, useState } from 'react'
import { CheckoutService } from 'Core/Service/Checkout'
import { useLocation, useHistory } from 'react-router-dom'
import { useUser } from 'Shared/contexts/User'
import { useToast } from '@bonitour/components'

export const useCheckoutCart = (checkoutId = '') => {
  const { push } = useHistory()
  const [isCartLoading, setLoadingState] = useState(false)
  const [shoppingCart, setShoppingCart] = useState({})
  const { add: addToast } = useToast()
  const { authKey } = useUser()
  const { pathname } = useLocation()

  const authPath = `/auth/login${pathname ? `?path=${pathname}` : ''}`

  const getCart = useCallback(async () => {
    if (!authKey) {
      push(authPath)
    } else {
      try {
        setLoadingState(true)
        const cart = await CheckoutService.get(checkoutId)
        setLoadingState(false)
        setShoppingCart(cart)
      } catch (error) {
        setLoadingState(false)

        if (error.status === 401) {
          push(authPath)
        } else {
          addToast('Carrinho não encontrado')
          push('/checkout/not-authorized')
        }
      }
    }
  }, [checkoutId, addToast, push, authKey, authPath])

  return { shoppingCart, isCartLoading, getCart }
}
