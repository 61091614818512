import React, { createContext, useContext, useCallback, useMemo, useEffect } from 'react'
import { useUser } from './User'
import { useHistory, useLocation } from 'react-router-dom'
import { UserStore } from 'Core/Store/User'

export const AuthorizationContext = createContext({})

export const useAuthorization = () => useContext(AuthorizationContext)

export const AuthorizationProvider = (props) => {
  const history = useHistory()
  const { authKey, updateAuthKey, user } = useUser()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!authKey || !user?.email) {
      history.push(`/auth/login${pathname ? `?path=${encodeURIComponent(pathname)}` : ''}`)
    }
  }, [authKey, history, pathname, user?.email])

  const logout = useCallback(() => {
    updateAuthKey()
    localStorage.clear()
    UserStore.clear()
  }, [updateAuthKey])

  const providerData = useMemo(() => ({ logout }), [logout])

  return (
    <AuthorizationContext.Provider value={providerData} {...props} />
  )
}
