/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, colors, flexCenteredSpaceBetweenRow, H2, P } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { NavLink, useLocation } from 'react-router-dom'
import { ForgotPasswordForm } from './Form/Form'
import { forgotPasswordFormSchema } from './Form/Form.schema'

const returnLink = css`
  margin: 5px 0;
  text-decoration: underline;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${colors.primary};
`

const defaultFormState = {}

const preventFormDefault = event => event.preventDefault()

export const ForgotPasswordPage = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultFormState, forgotPasswordFormSchema)
  const { search = '' } = useLocation()

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  return (
    <form onSubmit={preventFormDefault}>
      <H2>Esqueci minha senha</H2>
      <P>Digite seu e-mail de cadastro. Nós lhe enviaremos um e-mail com link para recadastrar sua senha.</P>
      <ForgotPasswordForm
        forgotPassword={form}
        forgotPasswordErrors={errors}
        onChange={onInputChange}
        onBlur={onInputBlur}
        onSubmit={onClick}
      />
      <div css={flexCenteredSpaceBetweenRow}>
        <NavLink to={{ pathname: '/auth/login', search }} css={returnLink}>Realizar Login</NavLink>
        <Button type='submit' onClick={onClick}>Enviar</Button>
      </div>
    </form>
  )
}
