import { Login } from 'Domains/Login/Login'
import { Signup } from 'Domains/Signup/Signup'
import { AuthLayout } from 'Domains/AuthLayout/AuthLayout'
import { ForgotPassword } from 'Domains/ForgotPassword/ForgotPassword'
import { ResetPassword } from 'Domains/ResetPassword/ResetPassword'
import { SocialLoginCallback } from 'Domains/Login/SocialLoginCallback'

export const publicRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        component: Login
      },
      {
        path: '/social-login-callback',
        component: SocialLoginCallback
      },
      {
        path: '/signup',
        component: Signup
      },
      {
        path: '/forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword
      }
    ]
  },
  {
    redirect: true,
    path: '/auth/login'
  }
]
