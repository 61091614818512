export const paymentFormI18nTranslation = {
  pt_BR: {
    cardNumber: "Número do cartão",
    pasteCardNumber:
      "Clique para colar o número do cartão da área de transferência",
    totalPriceInfo: "Valor a pagar",
    nameInCard: "Titular do cartão",
    cardNameTooltip: "O nome deve ser exatamente como está escrito no cartão.",
    creditCardFlag: "Bandeira do cartão",
    enterCardNumber: "Digite o número do cartão",
    notCreditCardFlag: "Bandeira não aceita",
    installmentsAmount: "Número de parcelas",
    expirationDate: "Validade do cartão",
    cvvField: "CVV",
  },
  en_US: {
    cardNumber: "Card number",
    pasteCardNumber: "Click to paste card number from clipboard",
    totalPriceInfo: "Total",
    nameInCard: "Name in card",
    cardNameTooltip: "The name must be exactly as written on the card.",
    creditCardFlag: "Card flag",
    enterCardNumber: "Enter card number",
    notCreditCardFlag: "Flag not accepted",
    installmentsAmount: "Number of installments",
    expirationDate: "Expiration date",
    cvvField: "CSV",
  },
  es_ES: {
    cardNumber: "Numero de tarjeta",
    pasteCardNumber:
      "Haga clic para pegar el número de tarjeta del portapapeles",
    totalPriceInfo: "Total",
    nameInCard: "Titular de la tarjeta",
    cardNameTooltip:
      "El nombre debe ser exactamente como está escrito en la tarjeta.",
    creditCardFlag: "Banner de tarjeta",
    enterCardNumber: "Ingrese el número de tarjeta",
    notCreditCardFlag: "Bandera no aceptada",
    installmentsAmount: "Número de plazos",
    expirationDate: "Vencimiento",
    cvvField: "CVV",
  },
};

export const paymentSchemaI18nTranslation = {
  pt_BR: {
    valuetPay: "Valor a pagar é obrigatório",
    heMustNumber: "Deve ser um número",
    installmentsNumber: "Número de parcelas é obrigatório",
    holderCard: "O titular do cartão é obrigatório",
    nameHolderCard:
      "Nome do titular do cartão deve ter pelo menos duas palavras",
    cardNumber: "Número do cartão é obrigatório",
    numberCardValid: "O número do cartão deve ser válido",
    flagCard: "Bandeira do cartão é obrigatória",
    cardValid: "Validade do cartão é obrigatória",
    dateValid: "A data de validade é inválida",
    numberMustBe: "Deve ser um número",
    codeVerification: "Código de verificação é obrigatório",
  },
  en_US: {
    valuetPay: "Amount to be paid is mandatory",
    heMustNumber: "Must be a number",
    installmentsNumber: "Number of installments is mandatory",
    holderCard: "Cardholder is required",
    nameHolderCard: "Cardholder name must be at least two words long",
    cardNumber: "Card number is required",
    numberCardValid: "The card number must be valid",
    flagCard: "Card flag is mandatory",
    cardValid: "Card validity is mandatory",
    dateValid: "The expiration date is invalid",
    numberMustBe: "must be a number",
    codeVerification: "Verification code is required",
  },
  es_ES: {
    valuetPay: "El monto a pagar es obligatorio",
    heMustNumber: "Tiene que ser un número",
    installmentsNumber: "El número de cuotas es obligatorio",
    holderCard: "Se requiere titular de la tarjeta",
    nameHolderCard:
      "El nombre del titular de la tarjeta debe tener al menos dos palabras",
    cardNumber: "Se requiere número de tarjeta",
    numberCardValid: "El número de tarjeta debe ser válido.",
    flagCard: "La bandera de la tarjeta es obligatoria",
    cardValid: "La validez de la tarjeta es obligatoria",
    dateValid: "La fecha de caducidad no es válida",
    numberMustBe: "Tiene que ser un número",
    codeVerification: "Se necesita un código de verificación",
  },
};
