/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Column, Row } from '@bonitour/components'

import { CheckoutCart } from './Cart'
import { CheckoutPaymentStep } from './Steps/Payment'
import { CheckoutInfoStep } from './Steps/Info'
import { identity } from '@bonitour/common-functions'

const stepsContainer = css`
  padding: 15px 10px;
  flex-grow: 1;
`

const hide = css`
  display: none;
`

const flexCenter = css`
  justify-content: center;
`

const formOrder = css`
  order: 1;

  @media screen and (max-width: 1199px){
    order: 2;
    margin-top: 15px;
  }
`

const cartOrder = css`
  order: 2;

  @media screen and (max-width: 1199px){
    order: 1;
  }
`

export const CheckoutFlux = ({
  cart = {},
  form = {},
  step = '',
  onInfoSuccess = identity,
  onPaymentBackButton = identity,
  onPaymentSuccess = identity,
  isCartLoading = false
}) => {
  const {
    info = { identificationDocumentType: 'Cpf' },
    payment = {}
  } = form
  const { maxInstallments } = cart

  return (
    <Row>
      <Column css={formOrder} desktop={9} phone={12}>
        <div css={stepsContainer}>
          <CheckoutInfoStep
            css={step !== 'info' && hide}
            info={info}
            onSuccess={onInfoSuccess}
          />
          <CheckoutPaymentStep
            css={step !== 'payment' && hide}
            payment={payment}
            maxInstallments={maxInstallments}
            onBackButton={onPaymentBackButton}
            onSuccess={onPaymentSuccess}
            isCartLoading={isCartLoading}
          />
        </div>
      </Column>
      <Column css={[flexCenter, cartOrder]} desktop={3} phone={12}>
        <CheckoutCart cart={cart} />
      </Column>
    </Row>
  )
}
