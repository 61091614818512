import { CheckoutService } from 'Core/Service/Checkout'
import { useEffect, useMemo, useState } from 'react'

export const useCreditCardFlags = (form = {}, maxInstallments = 10) => {
  const [cardFlags, setCardFlags] = useState([])

  useEffect(() => {
    CheckoutService.getCardBrands().then(setCardFlags)
  }, [])

  const cleanCardNumber = useMemo(() => form.cardNumber && form.cardNumber.replace(/\D/g, ''), [form.cardNumber])

  const selectedBrand = useMemo(() => cardFlags.find(({ validator }) => validator && validator(cleanCardNumber)), [cleanCardNumber, cardFlags])

  const cardOptions = useMemo(() => cardFlags.map(({ name, brand }) => ({ value: brand, label: name })), [cardFlags])

  const maxInstallmentsList = useMemo(() => [...Array(maxInstallments).keys()].map(item => ++item), [maxInstallments])

  const installmentsOptions = useMemo(() => {
    if (maxInstallments) return (maxInstallmentsList || []).map(item => ({ value: item, label: `${item}x sem juros` }))
    return ((selectedBrand && selectedBrand.intallmentsList) || []).map(item => ({ value: item, label: `${item}x sem juros` }))
  }
  , [selectedBrand, maxInstallments, maxInstallmentsList])

  return {
    selectedBrand,
    cardOptions,
    installmentsOptions
  }
}
