import { CheckoutContainer } from 'Domains/Checkout/Container'
import { NotAuthorized } from 'Domains/Checkout/NotAuthorized'
import { Checkout } from 'Domains/Checkout/Checkout'
import { PaymentSucceeded } from 'Domains/Checkout/PaymentSucceeded'
import { PaymentFailed } from 'Domains/Checkout/PaymentFailed'

export const privateRoutes = [
  {
    path: '/checkout',
    component: CheckoutContainer,
    routes: [
      {
        path: '/flux/:checkoutId/failed',
        exact: true,
        component: PaymentFailed
      },
      {
        path: '/flux/:reservationCode/succeeded',
        exact: true,
        component: PaymentSucceeded
      },
      {
        path: '/flux/:checkoutId',
        exact: true,
        component: Checkout
      },
      {
        path: '/not-authorized',
        exact: true,
        component: NotAuthorized
      },
      {
        redirect: true,
        path: '/not-authorized'
      }
    ]
  }
]
