import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { UserStore } from 'Core/Store/User'

export const UserContext = createContext({})

export const useUser = () => useContext(UserContext)

export const UserProvider = (props) => {
  const [user, setUser] = useState(() => UserStore.user)
  const [authKey, setAuthKey] = useState(() => UserStore.authKey)

  const updateUser = useCallback((user) => {
    UserStore.user = user
    setUser(user)
  }, [])

  const updateAuthKey = useCallback((newAuthKey) => {
    UserStore.authKey = newAuthKey
    setAuthKey(newAuthKey)
  }, [])

  const providerData = useMemo(() => ({
    user,
    updateUser,
    authKey,
    updateAuthKey
  }), [user, updateUser, authKey, updateAuthKey])

  return (
    <UserContext.Provider value={providerData} {...props} />
  )
}
