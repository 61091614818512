import * as Sentry from '@sentry/react'
import pkg from '../../../package.json'
import { UserStore } from 'Core/Store/User'

const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env
const { version } = pkg

export function SentrySetup () {
  const getSentryUserScope = () => {
    const { id, email } = UserStore.user
    return { id, email }
  }

  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      release: version,
      environment: NODE_ENV
    })

    Sentry.configureScope(function (scope) {
      scope.setUser(getSentryUserScope())
    })
  }
}
