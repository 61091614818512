/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { H1, H2, P } from '@bonitour/components'
import {
  container,
  reservationNumber,
  bold,
  qrcode,
  shopInfo,
  card,
  cardTitle,
  cardContent,
  cardTitleText,
  cardImageContainer,
  cardQrText,
  cashBackSteps,
  shopInfoTitle
} from './PaymentSucceeded.style'
import { UserStore } from 'Core/Store/User'
import { useParams } from 'react-router'
import AppleQRCode from 'Shared/images/QRCode/qr-code-applestore.png'
import AndroidQRCode from 'Shared/images/QRCode/qr-code-googleplay.png'

export const PaymentSucceeded = () => {
  const { reservationCode } = useParams()
  const { name } = UserStore.user

  return (
    <div css={container}>
      <div css={shopInfo}>
        <H1 css={shopInfoTitle}>Compra realizada com sucesso!</H1>
        <P>Olá <strong css={bold}>{name}</strong>.</P>
        {reservationCode && <P>O código da sua reserva é:  <strong><span css={[reservationNumber, bold]}>{reservationCode}</span></strong></P>}
        <P>Sua compra está confirmada e você receberá por e-mail todas as informações referentes ao seu roteiro.</P>
        <P>Você já pode acessar a sua compra, nomear ingressos e muito mais pelo aplicativo Binamik Ally, disponível para Android e iOS.</P>
        <P>Em breve enviaremos por e-mail todas as informações do seu roteiro.</P>
        <P>Boa viagem!</P>
      </div>
      <div css={card}>
        <div css={cardTitle}>
          <H2 css={cardTitleText}>Sua compra no aplicativo</H2>
        </div>
        <div css={cardContent}>
          <P>1 - Baixe o aplicativo nas lojas</P>
          <div css={cardImageContainer}>
            <div>
              <P css={cardQrText}>Apple Store</P>
              <a href='https://apps.apple.com/br/app/binamik-ally-guia-de-bolso/id1541872177?l=en' target='_blank' rel='noopener noreferrer'>
                <img css={qrcode} src={AppleQRCode} alt='App store QR code' />
              </a>
            </div>
            <div>
              <P css={cardQrText}>Google Play</P>
              <a href='https://play.google.com/store/apps/details?id=com.binamik.ally&hl=pt_BR&gl=US' target='_blank' rel='noopener noreferrer'>
                <img css={qrcode} src={AndroidQRCode} alt='Play store QR code' />
              </a>
            </div>
          </div>
          <div />
          <P css={cashBackSteps}>2- Faça o login com a sua conta.</P>
          <P css={cashBackSteps}>3- Tenha o seu roteiro nas palmas das mãos e muito mais!</P>
        </div>
      </div>
    </div>
  )
}
