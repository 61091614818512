export const infoFormI18nTranslation = {
  pt_BR: {
    fullNameLabel: "Nome Completo",
    documentLabel: "Documento",
    birthDateLabel: "Data de nascimento",
    phoneNumberLabel: "Telefone",
    zipCodeLabel: "CEP",
    addressLabel: "Endereço",
    addressNumberLabel: "Número",
    complementLabel: "Complemento",
    districtLabel: "Bairro",
    countryLabel: "País",
    stateLabel: "Estado",
    cityLabel: "Cidade",
  },

  en_US: {
    fullNameLabel: "Full Name",
    documentLabel: "Document",
    birthDateLabel: "Date of birth",
    phoneNumberLabel: "Phone",
    zipCodeLabel: "Postal code",
    addressLabel: "Street name",
    addressNumberLabel: "Number",
    complementLabel: "Complement",
    districtLabel: "District",
    countryLabel: "Country",
    stateLabel: "State",
    cityLabel: "City",
  },

  es_ES: {
    fullNameLabel: "Nombre Completo",
    documentLabel: "Documento",
    birthDateLabel: "Fecha de nacimiento",
    phoneNumberLabel: "Teléfono",
    zipCodeLabel: "Código postal",
    addressLabel: "Camino",
    addressNumberLabel: "Número",
    complementLabel: "Complemento",
    districtLabel: "Bairro",
    countryLabel: "País",
    stateLabel: "Estado",
    cityLabel: "Ciudad",
  },
};

export const infoSchemaI18nTranslation = {
  pt_BR: {
    fullNameAlert: [
      "O nome completo é obrigatório",
      "Nome completo deve ter pelo menos duas palavras",
    ],
    birthDateAlert: "A data de nascimento é obrigatória",
    birthDateInvalidAlert: "Data de nascimento incorreta",
    documentAlert: [
      "O CPF é obrigatório",
      "O CPF deve ser válido",
      "O documento é obrigatório",
    ],
    phoneNumberAlert: [
      "O telefone é obrigatório",
      "O número de telefone deve ser válido",
    ],
    addressNumberAlert: ["Deve ser um número", "O Número é obrigatório"],
    zipCodeAlert: "O CEP é um campo obrigatório",
    addressAlert: "O endereço é um campo obrigatório",
    districtAlert: "O bairro é um campo obrigatório",
    countryAlert: "O país é um campo obrigatório",
    stateAlert: "O estado é um campo obrigatório",
    cityAlert: "A cidade é um campo obrigatório",
    uuidAlert: "O campo deve ser um UUID válido",
  },

  en_US: {
    fullNameAlert: [
      "Full name is required",
      "Full name must have at least two words",
    ],
    birthDateAlert: "Date of birth is mandatory",
    birthDateInvalidAlert: "Incorrect date of birth",
    documentAlert: [
      "Document is mandatory",
      "CPF must be valid",
      "The document is mandatory",
    ],
    phoneNumberAlert: ["Phone is mandatory", "The phone number must be valid"],
    addressNumberAlert: ["Must be a number", "Number is mandatory"],
    zipCodeAlert: "Postal code is a required field",
    addressAlert: "Address is a required field",
    districtAlert: "The neighborhood is a required field",
    countryAlert: "Country is a required field",
    stateAlert: "State is a required field",
    cityAlert: "City is a required field",
    uuidAlert: "The field must be a valid UUID",
  },

  es_ES: {
    fullNameAlert: [
      "Nombre completo es obligatorio",
      "El nombre completo debe tener al menos dos palabras",
    ],
    birthDateAlert: "La fecha de nacimiento es obligatoria",
    birthDateInvalidAlert: "Fecha de nacimiento incorrecta",
    documentAlert: [
      "Documento es obligatorio",
      "CPF debe ser válido",
      "El documento es obligatorio.",
    ],
    phoneNumberAlert: [
      "El teléfono es obligatorio",
      "El número de teléfono debe ser válido.",
    ],
    addressNumberAlert: ["Tiene que ser un número", "El número es obligatorio"],
    zipCodeAlert: "El código postal es un campo obligatorio",
    addressAlert: "La dirección es un campo obligatorio",
    districtAlert: "Barrio es un campo obligatorio",
    countryAlert: "El país es un campo obligatorio",
    stateAlert: "El estado es un campo obligatorio",
    cityAlert: "La ciudad es un campo obligatorio",
    uuidAlert: "El campo debe ser un UUID válido",
  },
};
