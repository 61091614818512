import { OrbCore } from 'Core/Api/Orb'
import { CheckoutParser } from 'Core/Parser/Checkout'
import { CheckoutModel } from '@binamik/pay-checkout'
import { head, tail } from '@bonitour/common-functions'
import { Tokenizer } from 'Core/Api/Tokenizer'

const BRASPAG_URL = 'https://h.online-metrix.net/fp/tags.js'

const { REACT_APP_BRASPAG_ORG_ID, REACT_APP_BRASPAG_MERCHANT_ID } = process.env

const getInitials = (value = '') => {
  const stringArray = value.split(' ')
  if (stringArray.length > 1) {
    const firstLetter = head(stringArray).slice(0, 1)
    const secondLetter = tail(stringArray).slice(0, 1)
    return `${firstLetter}${secondLetter}`.toUpperCase()
  } else {
    return head(stringArray).slice(0, 2).toUpperCase()
  }
}

export const CheckoutService = {
  get (checkoutId = '') {
    return OrbCore.checkoutDomain.get(checkoutId).then(CheckoutParser.cart)
  },
  buildBraspagUrl (uuid = '') {
    return `${BRASPAG_URL}?org_id=${REACT_APP_BRASPAG_ORG_ID}&session_id=${REACT_APP_BRASPAG_MERCHANT_ID}${uuid}`
  },
  async pay (checkoutId = '', form = {}, email = '', uuid = '', ipAddress = '') {
    const { info } = form
    const { states, countries, cities } = info
    const country = getInitials(countries.find(({ value }) => value === info.country).label)
    const city = cities.find(({ value }) => value === info.city).label
    const state = getInitials(states.find(({ value }) => value === info.state).label)
    const braspagTokenRes = await OrbCore.braspagAccessTokenDomain.createToken().catch(() => null)

    if (!braspagTokenRes?.pay_access_token?.access_token) {
      throw new Error('Missing braspag access token')
    }

    const paymentToken = await Tokenizer(braspagTokenRes.pay_access_token.access_token).create({
      holderName: form.payment.holder,
      cardNumber: form.payment.cardNumber,
      expiration: form.payment.cardValidityDate,
      securityCode: form.payment.cardCode,
      cardType: form.payment.type
    }).catch(() => null)

    const payload = CheckoutModel.safePayment({
      payment: {
        paymentToken,
        type: form.payment.type,
        installments: form.payment.installments,
        value: form.payment.value,
        cardFlag: form.payment.cardFlag
      },
      info: { ...info, country, city, state, email },
      uuid,
      ipAddress
    })

    return OrbCore.checkoutDomainV2.pay(checkoutId, payload).then(CheckoutParser.reservationInfo)
  },
  getCardBrands () {
    return OrbCore.checkoutDomain.getCardBrands().then(CheckoutParser.cardBrand)
  }
}
