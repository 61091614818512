import { head, tail } from '@bonitour/common-functions'
import { TermsDialog, useToast } from '@bonitour/components'
import { UserStore } from 'Core/Store/User'
import React, { useEffect, useMemo, useState } from 'react'
import { useTerms } from 'Shared/hooks/useTerms'
import { useUser } from 'Shared/contexts/User'
import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'

dayjs.locale('pt-BR')

export const TermsHandler = () => {
  const user = UserStore.user
  const [isConfirmActionVisible, setConfirmActionVisible] = useState(false)
  const [userTerms, setUserTerms] = useState([])
  const { term: travelerTerm, acceptTerm } = useTerms('tourist_terms_of_use')
  const { term: privacyPolicy } = useTerms('privacy_policy')
  const { add: addToast } = useToast()
  const { updateUser } = useUser()

  const getCompanyAndPrivacyTerms = () => {
    const terms = Object.entries(user?.legal_agreements || {}).filter(legalAgreement =>
      (head(legalAgreement) === 'tourist_terms_of_use' && tail(legalAgreement) !== true) ||
      (head(legalAgreement) === 'privacy_policy' && tail(legalAgreement) !== true)
    )
    setUserTerms(terms)
  }

  const onSuccess = (isCompanyTerm, isPrivacyPolicy, isTravelerTerm) => {
    Promise.all([isTravelerTerm && acceptTerm(travelerTerm?.id), isPrivacyPolicy && acceptTerm(privacyPolicy?.id)])
      .then((userData) => {
        updateUser(head(userData)?.user)
        addToast('Termos aceitos com sucesso', 'success')
      }).catch(err => {
        if (
          err?.data?.errors_msg.some(msg =>
            msg.includes('::legal_agreement_id::taken')
          )
        ) {
          // Terms already accepted but cookie is outdated
          updateUser({
            ...user,
            legal_agreements: {
              ...user.legal_agreements,
              company_terms_of_use: user.legal_agreements.company_terms_of_use || isCompanyTerm,
              privacy_policy: user.legal_agreements.privacy_policy || isPrivacyPolicy,
              tourist_terms_of_use: user.legal_agreements.tourist_terms_of_use || isTravelerTerm
            }
          })
          addToast({ text: 'Termos aceitos com sucesso', type: 'success' })
        } else {
          addToast({
            text: 'Ocorreu um erro ao aceitar os termos',
            type: 'danger'
          })
        }
      })
    setConfirmActionVisible(false)
  }

  useEffect(() => {
    if (
      user?.legal_agreements?.privacy_policy === false ||
      user?.legal_agreements?.tourist_terms_of_use === false
    ) {
      setConfirmActionVisible(true)
    }
    getCompanyAndPrivacyTerms()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const lastUpdate = useMemo(
    () => dayjs(travelerTerm?.updatedAt).isAfter(dayjs(privacyPolicy?.updatedAt))
      ? travelerTerm?.updatedAt
      : privacyPolicy?.updatedAt,
    [privacyPolicy?.updatedAt, travelerTerm?.updatedAt]
  )

  return (
    <TermsDialog
      isVisible={isConfirmActionVisible}
      successCallback={onSuccess}
      lastUpdate={dayjs(lastUpdate).format('MMMM [de] YYYY')}
      companyTermSource={`${process.env.REACT_APP_ACCOUNTS_URL}/termo-de-uso`}
      travelerTermSource={`${process.env.REACT_APP_ACCOUNTS_URL}/termo-de-uso-viajante`}
      privacyPolicySource={`${process.env.REACT_APP_ACCOUNTS_URL}/politica-de-privacidade`}
      terms={userTerms}
    />
  )
}
