import { v4 as uuidv4 } from 'uuid'
import { useState, useEffect, useCallback } from 'react'
import { IpService } from 'Core/Service/Ip'
import { CheckoutService } from 'Core/Service/Checkout'
import { addIframe, addScript } from 'Shared/utils/dom'
import { useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { useUser } from 'Shared/contexts/User'

export const useCheckoutPayment = (checkoutId = '') => {
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const [isCheckoutLoading, setLoadingState] = useState(false)
  const [userIp, setUserIp] = useState('')
  const [userUUID, setUserUUID] = useState('')
  const { user: { email = '' } = {} } = useUser()

  useEffect(() => {
    IpService.get().then(setUserIp)
  }, [])

  useEffect(() => {
    if (userUUID) {
      const braspagUrl = CheckoutService.buildBraspagUrl(userUUID)
      addIframe(braspagUrl)
      addScript(braspagUrl)
    } else {
      setUserUUID(uuidv4())
    }
  }, [userUUID])

  const payCheckout = useCallback(async (form) => {
    try {
      setLoadingState(true)
      const { reservationCode } = await CheckoutService.pay(checkoutId, form, email, userUUID, userIp)
      addToast('Pagamento finalizado', 'success')
      push(`/checkout/flux/${reservationCode}/succeeded`)
      setLoadingState(false)
    } catch (error) {
      setLoadingState(false)
      push(`/checkout/flux/${checkoutId}/failed`)
      addToast('Ocorreu um erro no pagamento')
    }
  }, [push, checkoutId, userUUID, userIp, addToast, email])

  return { payCheckout, isCheckoutLoading }
}
