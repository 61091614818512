import { AuthApi } from 'Core/Api/Auth'
import { AuthParser } from 'Core/Parser/Auth'

export const AuthService = {
  login (payload) {
    return AuthApi.login(payload)
      .then(AuthParser.payload)
  },
  signup (payload) {
    return AuthApi.signup(payload)
      .then(AuthParser.payload)
  },
  forgotPassword (payload) {
    return AuthApi.forgotPassword(payload)
  },
  oauthCallback (payload) {
    return AuthApi.oauthCallback(payload)
      .then(AuthParser.authorization)
  }
}
