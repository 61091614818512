/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Button, H2 } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'

import { LoginForm } from './Form/Form'
import { loginFormSchema } from './Form/Form.schema'
import { loginFormContent } from './Login.styles'
import { useQuery } from 'Shared/hooks/useQuery'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'

const defaultFormState = {}

const preventFormDefault = event => event.preventDefault()

const GOOGLE_OAUTH_PATH = '/login/external_providers/google_oauth_redirect'
const { REACT_APP_API_HOST: ORB_API_URL } = process.env

export const LoginPage = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity
}) => {
  const { search = '' } = useLocation()
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultFormState, loginFormSchema)

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  const { path } = useQuery()

  const onGoogle = useCallback(() => {
    const oauthUrl = new URL(GOOGLE_OAUTH_PATH, ORB_API_URL)
    oauthUrl.searchParams.append('auth_origin', window.location.origin)
    if (path) {
      const extraData = JSON.stringify({ path })
      oauthUrl.searchParams.append('extra_data', extraData)
    }
    window.location = oauthUrl
  }, [path])

  return (
    <form onSubmit={preventFormDefault}>
      <H2>Fazer Login</H2>
      <div css={loginFormContent}>
        <LoginForm
          loginForm={form}
          loginFormErrors={errors}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onSubmit={onClick}
        />
        <Button
          type='submit'
          onClick={onClick}
          className='btn_email'
          tabIndex={1}
        >
          Entrar
        </Button>
        <div className='signup-container'>
          Novo na plataforma?{' '}
          <NavLink to={{ pathname: '/auth/signup', search }} css={returnLink}>
            Cadastre-se
          </NavLink>
        </div>

        <div className="or-container">
          <div className="or-line"></div>
          <div className="or-text">Ou</div>
          <div className="or-line"></div>
        </div>

        <Button className='btn_google' onClick={onGoogle}>
          <img src={'/icons/google.svg'} alt='' />
            Entrar com o Google
        </Button>
      </div>
    </form>
  )
}
