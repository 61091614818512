/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "@bonitour/components";
import { JustWithChildren } from "@/types/Utils";
import { useMemo } from "react";

const cartItem = css`
  padding: 20px 10px 20px 20px;
  font-size: 14px;
  border: 1px dashed ${colors.gray7};
  border-radius: 10px;
  margin-bottom: 20px;
`;

const itemTitle = css`
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  color: ${colors.gray3};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
`;

const itemSubtitle = css`
  color: ${colors.gray5};
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const itemInfo = css`
  font-weight: 500;
  color: ${colors.gray3};
  display: flex;
  flex-direction: column;
`;

const cartItemsContainer = css`
  max-height: 380px;
  padding-right: 15px;
  overflow-y: scroll;
  border-bottom: 1px solid ${colors.gray8};

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.gray14};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.gray9};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray6};
  }
`;

type CartItemProps = {
  title: string;
  subTitle: string;
  date?: string;
  offlineInfo?: {
    checkinDate?: string;
    checkoutDate?: string;
  };
  description?: string;
};

export const CartItem = ({
  title = "",
  subTitle = "",
  date = "",
  offlineInfo = {},
  description = "",
}: CartItemProps): JSX.Element => {
  const { checkinDate = "", checkoutDate = "" } = offlineInfo;

  const isDateRange = useMemo(
    () =>
      Boolean(
        checkinDate.length &&
          checkoutDate.length &&
          checkinDate !== checkoutDate,
      ),
    [checkinDate, checkoutDate],
  );

  return (
    <div css={cartItem}>
      <div css={itemTitle}>{title}</div>
      <div css={itemSubtitle}>{subTitle}</div>
      <div css={itemInfo}>
        {isDateRange ? `${checkinDate} - ${checkoutDate}` : date}
        <br />
        {description}
      </div>
    </div>
  );
};

export const CartItemsContainer = ({
  children,
}: JustWithChildren): JSX.Element => (
  <div css={cartItemsContainer}>{children}</div>
);
