import { GoogleTagManagerHeader, GoogleTagManagerBody } from './googleTagManager'

const { REACT_APP_GTM_ID } = process.env

export const HeaderIntegrations = () => {
  GoogleTagManagerHeader(REACT_APP_GTM_ID)
}

export const BodyIntegrations = () => {
  GoogleTagManagerBody(REACT_APP_GTM_ID)
}

export const ExternalIntegrations = () => {
  HeaderIntegrations()
  BodyIntegrations()
}
