import axios from 'axios'

export function Tokenizer (braspagAccessToken) {
  return {
    create: async (cardData) => {
      const tokenizationUrl = process.env.REACT_APP_BRASPAG_TOKENIZATION_URL

      const {
        holderName = '',
        cardNumber,
        expiration,
        securityCode,
        cardType
      } = cardData

      if (!tokenizationUrl) {
        throw new Error('Tokenization url not provided')
      }

      if (!braspagAccessToken) {
        throw new Error('Braspag auth token not provided')
      }

      const parsedType = cardType === 'debit_card' ? 'debitcard' : 'creditcard'
      const rawNumber = cardNumber.replace(/\D/gi, '')
      const [month, year] = expiration.split('/')
      const formattedExpirationDate = `${month}/${`20${year}`.slice(-4)}`
      const parsedSecurityCode = `${securityCode}`

      const cleanName = holderName.replace(/\s+/g, ' ')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const data = {
        AccessToken: braspagAccessToken,
        HolderName: cleanName,
        RawNumber: rawNumber,
        Expiration: formattedExpirationDate,
        SecurityCode: parsedSecurityCode,
        EnableBinQuery: 'true',
        EnableVerifyCard: 'true',
        CardType: parsedType
      }

      const dataArray = Object.entries(data)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      const tokenRes = await axios({
        method: 'post',
        url: tokenizationUrl,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: dataArray
      }).catch(() => null)

      if (!tokenRes || !tokenRes.data || !tokenRes.data.PaymentToken) {
        throw new Error('Error generating Braspag payment token')
      }

      const paymentToken = tokenRes.data.PaymentToken

      return paymentToken
    }
  }
}
