/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { colors } from "@bonitour/components";
import { JustWithChildren } from "@/types/Utils";

const cartDetails = css`
  padding: 20px;
  background-color: ${colors.white};
`;

export const CartDetails = ({ children }: JustWithChildren): JSX.Element => (
  <div css={cartDetails}>{children}</div>
);
