import { css } from '@emotion/core'
import { colors } from '@bonitour/components'

export const inputMargin = css`
  margin: 20px 0;
`

export const checkboxContainer = css`
  position: relative;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;
  font-weight: 600;
  color: ${colors.gray3};

  a {
    color: ${colors.gray3};
  }
`

export const checkbox = css`
  margin-right: 8px;
  min-width: 26px;
`

export const errorTooltip = css`
  position: absolute;
  bottom: 10px;
  margin-bottom: 5px;
  transition: all 0.3s ease-in;

  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
`

export const errorTooltipHorizontalForm = css`
  top: auto;
  bottom: 100%;
`
export const hideError = (isHidden) => {
  if (isHidden) {
    return css`
      opacity: 0;
      transform: translateY(-10px);
    `
  }
  return css`
    opacity: 1;
    transform: translateY(0);
  `
}
