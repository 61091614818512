/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import {
  InputFormGroup,
  Input,
  Column,
  Row,
  Select,
  Card,
  InputMoneyMask,
  MaskedInput,
  MaskedInputWithSuffix,
  PasteIcon,
  TooltipOnHover,
  InfoIcon,
  Label,
} from "@bonitour/components";
import {
  identity,
  maskCreditCard,
  maskExpirationDate,
} from "@bonitour/common-functions";
import { string, number } from "yup";
import {
  validateExpirationDate,
  validateCreditCard,
} from "@bonitour/common-functions";
import { FormCallback, SelectOption } from "@/types/Form";
import { useMemo } from "react";
import { getLanguageCode } from "../../../utils/getLanguageCode";

import {
  paymentFormI18nTranslation,
  paymentSchemaI18nTranslation,
} from "./i18n/paymentFormDictinoaryI18n";
import {
  cardNameContainer,
  fullWidth,
  marginBottom20,
  pasteIcon,
} from "./PaymentForm.style";

type PaymentFormFields = {
  type?: string;
  value?: string;
  installments?: 0;
  holder?: string;
  cardNumber?: string;
  cardFlag?: string;
  cardValidityDate?: string;
  cardCode?: string;
};

interface PaymentFormInterface {
  lang?: string;
  form: PaymentFormFields;
  errors: PaymentFormFields;
  installmentsOptions: Array<SelectOption>;
  cardOptions: Array<SelectOption>;
  onInputBlur: FormCallback;
  onInputChange: FormCallback;
  isFlagSelectDisabled: boolean;
  children?: React.ReactNode;
}

const DefaultCheckoutPaymentForm = ({
  lang,
  form = {},
  errors = {},
  onInputChange: emitInputChangeEvent = identity,
  onInputBlur: emitInputBlurEvent = identity,
  installmentsOptions = [],
  cardOptions = [],
  isFlagSelectDisabled = false,
  children,
}: PaymentFormInterface): JSX.Element => {
  const {
    value = "",
    installments = 0,
    holder = "",
    cardNumber = "",
    cardFlag = "",
    cardValidityDate = "",
    cardCode = "",
  } = form;

  const invalidCardBrand = useMemo(
    () =>
      Boolean(
        form.cardNumber?.replace(/[^0-9]/g, "").length > 14 && !form.cardFlag,
      ),
    [form.cardNumber, form.cardFlag],
  );

  const pasteCardNumberFromClipboard = () => {
    navigator?.clipboard?.readText().then((clipboardText) => {
      clipboardText && emitInputChangeEvent("cardNumber")(clipboardText);
    });
  };

  const displayPasteButton = useMemo(
    () => !navigator.userAgent.toLowerCase().includes("firefox"),
    [],
  );

  return (
    <Card css={marginBottom20}>
      <Row>
        <Column phone={12} desktop={6}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].cardNumber}
            errorMessage={errors.cardNumber}
            id="card-number"
          >
            <MaskedInputWithSuffix
              css={fullWidth}
              value={cardNumber}
              formatterFunction={maskCreditCard}
              onChange={emitInputChangeEvent("cardNumber")}
              onBlur={emitInputBlurEvent("cardNumber")}
              className="card-number"
              disabled={false}
              readOnly={false}
              {...{ autoComplete: "cc-number" }}
            >
              {displayPasteButton ? (
                <TooltipOnHover
                  size="180"
                  text={paymentFormI18nTranslation[lang].pasteCardNumber}
                >
                  <PasteIcon
                    css={pasteIcon}
                    onClick={pasteCardNumberFromClipboard}
                  />
                </TooltipOnHover>
              ) : null}
            </MaskedInputWithSuffix>
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={6}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].totalPriceInfo}
            id="value"
          >
            <InputMoneyMask disabled value={value} error={errors.value} />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} desktop={6}>
          <div css={cardNameContainer}>
            <div className="card_name__row">
              <Label>{paymentFormI18nTranslation[lang].nameInCard}</Label>
              <TooltipOnHover
                className="icon__ctn"
                size="250"
                text={paymentFormI18nTranslation[lang].cardNameTooltip}
              >
                <InfoIcon className="icon" />
              </TooltipOnHover>
            </div>
            <InputFormGroup errorMessage={errors.holder} id="card-holder">
              <Input
                value={holder}
                error={errors.holder}
                onChange={emitInputChangeEvent("holder")}
                onBlur={emitInputBlurEvent("holder")}
                autoComplete="cc-name"
              />
            </InputFormGroup>
          </div>
        </Column>
        <Column phone={12} desktop={6}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].creditCardFlag}
            css={fullWidth}
            id="card-flag"
          >
            <Select
              options={cardOptions}
              value={cardFlag}
              error={errors.cardFlag}
              disabled={isFlagSelectDisabled}
              placeholder={
                invalidCardBrand
                  ? paymentFormI18nTranslation[lang].notCreditCardFlag
                  : paymentFormI18nTranslation[lang].enterCardNumber
              }
              onChange={emitInputChangeEvent("cardFlag")}
              onBlur={emitInputBlurEvent("cardFlag")}
              autoComplete="cc-type"
            />
          </InputFormGroup>
        </Column>
      </Row>
      <Row>
        <Column phone={12} tabletLandscape={6} desktop={4}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].installmentsAmount}
            id="insatallments"
          >
            <Select
              options={installmentsOptions}
              value={installments}
              error={errors.installments}
              onChange={emitInputChangeEvent("installments")}
              onBlur={emitInputBlurEvent("installments")}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletLandscape={6} desktop={4}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].expirationDate}
            errorMessage={errors.cardValidityDate}
            id="vailidity"
          >
            <MaskedInput
              css={fullWidth}
              value={cardValidityDate}
              maxLength={8}
              formatterFunction={maskExpirationDate}
              onChange={emitInputChangeEvent("cardValidityDate")}
              onBlur={emitInputBlurEvent("cardValidityDate")}
              autoComplete="cc-exp"
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} tabletLandscape={6} desktop={4}>
          <InputFormGroup
            label={paymentFormI18nTranslation[lang].cvvField}
            id="cvv"
          >
            <Input
              css={fullWidth}
              type="number"
              value={cardCode}
              error={errors.cardCode}
              maxLength={4}
              max={9999}
              onChange={emitInputChangeEvent("cardCode")}
              onBlur={emitInputBlurEvent("cardCode")}
              autoComplete="cc-csc"
            />
          </InputFormGroup>
        </Column>
      </Row>
      {children}
    </Card>
  );
};

export const CheckoutPaymentForm = ({
  lang = "pt",
  ...props
}: PaymentFormInterface): JSX.Element => (
  <DefaultCheckoutPaymentForm lang={getLanguageCode(lang)} {...props} />
);

export const customCheckoutPaymentSchema = ({
  lang,
}: {
  lang?: string;
}): Record<string, unknown> => {
  const langCode = getLanguageCode(lang);

  return {
    value: string().required(paymentSchemaI18nTranslation[langCode].valuetPay),
    installments: number()
      .typeError(paymentSchemaI18nTranslation[langCode].heMustNumber)
      .required(paymentSchemaI18nTranslation[langCode].installmentsNumber),
    holder: string()
      .trim()
      .required(paymentSchemaI18nTranslation[langCode].holderCard)
      .matches(/\s/, paymentSchemaI18nTranslation[langCode].nameHolderCard),
    cardNumber: string()
      .required(paymentSchemaI18nTranslation[langCode].cardNumber)
      .test(
        "test-validity",
        paymentSchemaI18nTranslation[langCode].numberCardValid,
        validateCreditCard,
      ),
    cardFlag: string().required(
      paymentSchemaI18nTranslation[langCode].flagCard,
    ),
    cardValidityDate: string()
      .required(paymentSchemaI18nTranslation[langCode].cardValid)
      .test(
        "test-validity",
        paymentSchemaI18nTranslation[langCode].dateValid,
        validateExpirationDate,
      ),
    cardCode: number()
      .typeError(paymentSchemaI18nTranslation[langCode].numberMustBe)
      .required(paymentSchemaI18nTranslation[langCode].codeVerification),
  };
};

export const checkoutPaymentSchema = customCheckoutPaymentSchema({
  lang: "pt",
});
