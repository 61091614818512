/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { EmptyResult } from '@bonitour/components'

export const PaymentFailed = () => (
  <EmptyResult
    size={258}
    title='Pagamento falhou'
    subtitle='Ocorreu um erro no pagamento, tente novamente em breve'
  />
)
