import { OrbCore } from 'Core/Api/Orb'
import { TermParser } from 'Core/Parser/Terms'

const { termDomain } = OrbCore

export const Termservice = {
  getByType (type) {
    return termDomain.getByType(type).then(TermParser.get)
  },
  acceptTerm (termId) {
    return termDomain.acceptTerm(termId)
  }
}
