/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { EmptyResult } from '@bonitour/components'

export const NotAuthorized = () => (
  <EmptyResult
    size={258}
    title='Reserva não encontrada'
    subtitle='A Reserva expirou ou o código está inválido'
  />
)
