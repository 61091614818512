/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { colors, Header, Logo, Footer } from '@bonitour/components'
import logoPay from 'Shared/svgs/logoPay.svg'

import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { Feature } from 'Shared/contexts/Feature'
import { TermsHandler } from 'Domains/Terms/TermsHandler'

const body = css`
  @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap");

  body {
    background-color: ${colors.gray15};
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    /* Rounded Square appearence: */
    --round-element-radius: 10px;
    --small-round-element-radius: 6px;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
  }
`

const maxWidthContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-self: center;
  width: 100%;
  max-width: 1268px;
  padding: 0 20px;
  padding-bottom: 15px;
  box-sizing: border-box;
`

const header = css`
  flex: 0 1 auto;
`

const footer = css`
  flex: 0 1 57px;
`

const logo = css`
  max-width: 100px;
  margin: 7px auto;
`

export const CheckoutContainer = ({ children }) => {
  return (
    <Feature>
      <AuthorizationProvider>
        <Global styles={body} />
        <Header css={header}>
          <Logo src={logoPay} css={logo} />
        </Header>
        <div css={maxWidthContainer}>{children}</div>
        <TermsHandler />
        <Footer css={footer} />
      </AuthorizationProvider>
    </Feature>
  )
}
