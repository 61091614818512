import Axios from 'axios'

const IpServiceUrls = [
  'https://binamik-serverless.vercel.app/api/ip',
  'https://ipapi.co/json',
  'https://freeipapi.com/api/json',
  'https://api.ipify.org/?format=json'
]

const IpServices = IpServiceUrls.map(baseURL => Axios.create({ baseURL }))

export const IpApi = {
  get () {
    const promises = IpServices.map(IpService =>
      IpService.get('')
        .then(({ data }) => data)
        .then(({ ip, ipAdress }) => {
          if (!ip && !ipAdress) {
            throw new Error('Request to API failed')
          }
          return ip || ipAdress
        })
    )
    return Promise.any(promises)
  }
}
