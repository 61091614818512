/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { useEffect, useState } from 'react'
import { jsx } from '@emotion/core'

import { head } from '@bonitour/common-functions'
import { CheckoutFlux } from './Flux/Flux'
import { useCheckoutCart } from './hooks/useCheckoutCart'
import { useCheckoutPayment } from './hooks/useCheckoutPayment'
import { useParams } from 'react-router-dom'
import { CheckoutLoading, CartLoading } from './Loading'
import { useToast } from '@bonitour/components'

const checkoutSteps = ['info', 'payment']

export const Checkout = () => {
  const { checkoutId } = useParams()
  const { add: addToast } = useToast()

  const [step, setStep] = useState(head(checkoutSteps))
  const [form, setForm] = useState({})
  const { shoppingCart, isCartLoading, getCart } = useCheckoutCart(checkoutId)
  const { isCheckoutLoading, payCheckout } = useCheckoutPayment(checkoutId)

  useEffect(() => {
    getCart()
  }, [getCart])

  useEffect(() => {
    if (shoppingCart && shoppingCart.total) {
      setForm(({ payment = {}, ...rest }) => {
        return { ...rest, payment: { ...payment, value: shoppingCart.total } }
      })
    }
  }, [shoppingCart])

  const onInfoSuccess = (data) => {
    setForm({ ...form, [step]: data })
    setStep('payment')
  }
  const onPaymentBackButton = () => {
    setStep('info')
  }
  const onPaymentSuccess = (data) => {
    payCheckout({ ...form, [step]: data })
    addToast('Os dados foram preenchidos com sucesso', 'success')
  }

  return (
    <>
      <CheckoutFlux
        form={form}
        step={step}
        cart={shoppingCart}
        onInfoSuccess={onInfoSuccess}
        onPaymentBackButton={onPaymentBackButton}
        onPaymentSuccess={onPaymentSuccess}
        isCartLoading={isCartLoading}
      />
      <CheckoutLoading isLoading={isCheckoutLoading} />
      <CartLoading isLoading={isCartLoading} />
    </>
  )
}
